import {createSlice} from '@reduxjs/toolkit';

const mediaSlice = createSlice({
    name: 'media',
    initialState: {
        mediaLoading:false,
        mediaList: [],
    },
    reducers: {
        setMediaList: (state, {payload}) => {
            state.mediaList = payload;
        },
        setMediaListLoading: (state, {payload}) => {
            state.mediaLoading = payload;
        },
    },
});

export const { setMediaList,setMediaListLoading} = mediaSlice.actions;

export const mediaSelector = state => state.media;
const mediaReducer = mediaSlice.reducer;
export default mediaReducer;
