import {createSlice} from '@reduxjs/toolkit';

const postSlicer = (name)=> createSlice({
    name,
    initialState: {
        postLoading:false,
        postListLoading:false,
        formData:{},
        postList:[],
        postedData:[]
    },
    reducers: {
        [`set${name}Loading`]: (state, {payload}) => {
            state.postLoading = payload;
        },
        [`set${name}ListLoading`]: (state, {payload}) => {
            state.postListLoading = payload;
        },
        [`set${name}FormData`]: (state, {payload}) => {
            state.formData = {...state.formData, ...payload};
        },
        [`set${name}List`]: (state, {payload}) => {
            state.postList = payload
        },
        [`set${name}ListUpdate`]: (state, {payload}) => {
            state.postList.push(payload)
        },
        [`set${name}Data`]: (state,{payload}) => {
            state.formData = payload
        }
    },
});

export const {setJobFormData,setJobListUpdate,setJobList,setJobLoading,setJobListLoading,setJobData} = postSlicer('Job').actions;
export const {setStaticFormData,setStaticListUpdate,setStaticList,setStaticLoading,setStaticListLoading,setStaticData} = postSlicer('Static').actions;
export const {setResumeFormData,setResumeListUpdate,setResumeList,setResumeLoading,setResumeListLoading,setResumeData} = postSlicer('Resume').actions;
export const {setBlogFormData,setBlogListUpdate,setBlogList,setBlogLoading,setBlogListLoading,setBlogData} = postSlicer('Blog').actions;
export const {setTypeFormData,setTypeListUpdate,setTypeList,setTypeLoading,setTypeListLoading,setTypeData} = postSlicer('Type').actions;
export const {setCategoryFormData,setCategoryListUpdate,setCategoryList,setCategoryLoading,setCategoryListLoading,setCategoryData} = postSlicer('Category').actions;
export const {setTagFormData,setTagListUpdate,setTagList,setTagLoading,setTagListLoading,setTagData} = postSlicer('Tag').actions;

export const staticSelector = state => state.static;
export const jobSelector = state => state.job;
export const resumeSelector = state => state.resume;
export const blogSelector = state => state.blog;
export const typeSelector = state => state.blogType;
export const blogCategorySelector = state => state.blogCategory;
export const blogTagSelector = state => state.blogTag;


export const staticReducer = postSlicer('Static').reducer;
export const jobReducer = postSlicer('Job').reducer;
export const resumeReducer = postSlicer('Resume').reducer;
export const blogReducer = postSlicer('Blog').reducer;
export const typeReducer = postSlicer('Type').reducer;
export const blogCategoryReducer = postSlicer('Category').reducer;
export const blogTagReducer = postSlicer('Tag').reducer;
