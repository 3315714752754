import {combineReducers, configureStore} from '@reduxjs/toolkit';
import sessionReducer from './slicers/sessionSlice.js';
import listReducer from "./slicers/listSlicer";
import helpReducer from "./slicers/helpSlicer";
import mediaReducer from "./slicers/mediaSlicer";
import articleReducer from "./slicers/articleSlicer";
import {
   staticReducer,
   jobReducer,
   resumeReducer,
   blogReducer,
   typeReducer,
   blogCategoryReducer, blogTagReducer
} from "./slicers/postSlicer";

const rootReducer = combineReducers({
   session: sessionReducer,
   list: listReducer,
   static:staticReducer,
   job: jobReducer,
   resume:resumeReducer,
   blog:blogReducer,
   blogType:typeReducer,
   blogCategory:blogCategoryReducer,
   blogTag:blogTagReducer,
   article: articleReducer,
   help: helpReducer,
   media:mediaReducer
});

const store = configureStore({ reducer: rootReducer });
export default store;
