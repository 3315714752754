import {Provider} from "react-redux";
import store from "../redux/store";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-loading-skeleton/dist/skeleton.css'
import 'suneditor/dist/css/suneditor.min.css';
import Script from "next/script";
import draftToHtml from 'draftjs-to-html';
import {useRouter} from "next/router";
import {useEffect} from "react";
import '/styles/app.css'
import '/styles/globals.css'
import '@fortawesome/fontawesome-free/css/all.min.css';



    Object.defineProperty(RegExp.prototype, "toJSON", {
        value: RegExp.prototype.toString
    });


function MyApp(props) {
    const {Component, pageProps} = props
    const faq = pageProps?.data?.post_data?.faq ?? [];
    let jsonData = "";
    if (faq.length > 0) {
        jsonData = {"@context": "https://schema.org", "@type": "FAQPage"};
        jsonData['mainEntity'] = faq.flatMap((a) => a?.answer ? (
            {
                "@type": "Question",
                "name": a?.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": draftToHtml(a.answer),
                }
            }
        ) : [])
    }

    const router = useRouter();
    useEffect(() => {
        router.events.on('routeChangeComplete', () => {
            document.querySelectorAll('.page-view-container').forEach(a => {
                a.classList.remove('page-view-container')
            })
        })
    }, [])

    return <>
        {jsonData && <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(jsonData)}}
                             id={"__next_faq"}/>}
        <Script src="/assets/js/app.js"/>
        <Provider store={store}>
            <Component {...pageProps} />
        </Provider>
    </>
}


export default MyApp;
