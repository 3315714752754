import {createSlice} from '@reduxjs/toolkit';

const listSlice = createSlice({
    name: 'list',
    initialState: {
        listLoading:false,
        categoryData: {},
        categoryList: [],
        formData:{}
    },
    reducers: {
        setCategoryData: (state, {payload}) => {
            const {id, data = []} = payload
            state.categoryData[id] = data;
        },
        setCategoryList: (state, {payload}) => {
            state.categoryList = payload;
        },
        setListLoading: (state, {payload}) => {
            state.listLoading = payload;
        },
        setFormData: (state, {payload}) => {
            state.formData = {...state.formData, ...payload};
        }
    },
});

export const {setCategoryData, setCategoryList,setFormData,setListLoading} = listSlice.actions;

export const listSelector = state => state.list;
const listReducer = listSlice.reducer;
export default listReducer;
