import {createSlice} from '@reduxjs/toolkit';

const helpSlice = createSlice({
    name: 'helpCategory',
    initialState: {
        helpLoading: false,
        helpListLoading: false,
        formData: {},
        individualJobList: [],
        categoryList: [],
        topicList: [],
        helpPostList:[],
        tagList:[],
        pageStaticList:[]
    },
    reducers: {
        setHelpLoading: (state, { payload }) => {
            state.helpLoading = payload;
        },
        setHelpListLoading: (state, { payload }) => {
            state.helpListLoading = payload;
        },
        setJobFromData: (state, { payload }) => {
            state.formData = { ...state.formData, ...payload };
        },
        setIndividualJobList: (state, { payload }) => {
            state.individualJobList = payload
        },
        setCategoryList: (state, { payload }) => {
            state.categoryList = payload
        },
        savedCategoryData: (state, { payload }) => {
            state.categoryList = { ...state.categoryList, ...payload };
        },
        setTopicList: (state, { payload }) => {
            state.topicList = { ...state.topicList, ...payload }
        },
        setTopicsList: (state, { payload }) => {
            state.topicList = payload
        },
        setHelpPostList: (state, { payload }) => {
            state.helpPostList = payload
        },
        setTagList: (state, { payload }) => {
            state.tagList = payload
        },
        setPageStaticList: (state, { payload })=>{
            state.pageStaticList = payload
        }
    },
});

export const { setCategoryList, setTopicList, setTopicsList, setHelpLoading, setHelpListLoading, setHelpPostList,setTagList,setPageStaticList } = helpSlice.actions;
export const helpSelector = state => state.help;
const helpReducer = helpSlice.reducer;
export default helpReducer;
