import {createSlice} from '@reduxjs/toolkit';

const articleSlice = createSlice({
    name: 'article',
    initialState: {
        articleLoading:false,
        articleListLoading:false,
        authorListLoading:false,
        draftLoading:false,
        scheduleLoading:false,
        formData:{},
        articleList:[],
        authorList:[],
        individualArticleList:[],
        articleTopicLoading:false,
        articleCategoryList:[],
        articleTopicList:[]
    },
    reducers: {
        setArticleLoading: (state, {payload}) => {
            state.articleLoading = payload;
        },
        setArticleListLoading: (state, {payload}) => {
            state.articleListLoading = payload;
        },
        setArticleTopicLoading: (state, {payload}) => {
            state.articleTopicLoading = payload;
        },
        setArticleFormData: (state, {payload}) => {
            state.formData = {...state.formData, ...payload};
        },
        setArticleCategoryList: (state, {payload}) => {
            state.articleCategoryList = payload
        },
        setArticleTopicList: (state, {payload}) => {
            state.articleTopicList = payload
        },
        setAuthorList: (state, {payload}) => {
            state.authorList = payload
        },
        setAuthorListLoading: (state, {payload}) => {
            state.authorListLoading = payload
        },
        setArticleList: (state, {payload}) => {
            state.articleList = payload
        },
        setIndividualArticleList: (state,{payload}) => {
            state.individualArticleList = payload
        },
        setDraftLoading: (state, {payload}) => {
            state.draftLoading = payload;
        },
        setScheduleLoading: (state, {payload}) => {
            state.scheduleLoading = payload;
        },
    },
});

export const {setIndividualArticleList,setScheduleLoading,setDraftLoading,setAuthorListLoading,setAuthorList,setArticleCategoryList,setArticleTopicList,setArticleTopicLoading,setArticleFormData,setArticleList,setArticleLoading,setArticleListLoading} = articleSlice.actions;

export const articleSelector = state => state.article;
const articleReducer = articleSlice.reducer;
export default articleReducer;
